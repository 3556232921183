import type { DetailedHTMLProps } from 'react'
import styles from './MarkdownImage.module.scss'
import { ContentTypeView, strapiPublicImage } from 'utility/utility'

export type MarkdownImageProps = Omit<DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'>

export const MarkdownImage: ContentTypeView<MarkdownImageProps> = ({
    src,
    alt,
    ...img
}) => {

    const url = new URL(strapiPublicImage(src ?? ''))

    const dataParams =
        Object.fromEntries(
            Array.from(url.searchParams.entries())
                .map( ([key,value]) => [`data-${key}`,value] )
        )

    return (
        <picture>
            <img
                {...img}
                className={styles.MarkdownImage}
                src={ src === undefined ? '': strapiPublicImage(src) }
                alt={ alt === undefined ? '' : alt }
                {...dataParams}
            />
        </picture>
    )

}
