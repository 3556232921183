import { BodyObjectView, key } from 'utility/utility'
import { ComponentBodyProseFragment } from 'generated/graphql'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { Theme } from 'content-types/Theme/Theme'

import styles from './ComponentBodyProse.module.scss'

export const ComponentBodyProse: BodyObjectView<ComponentBodyProseFragment> =
    ({
        body,
        sectionBackground,
        siteContext,
        pageContext,
        articleContext,
        theme,
        textAlign,
        ...object
    }) =>
        <section
            id={key(object)}
            className={styles.ComponentBodyProse}
            data-background={sectionBackground?.sectionBackground}
            data-align={textAlign}
            style={ Theme(theme?.data?.attributes) }
        >
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >{body}</Markdown>
        </section>
