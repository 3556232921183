import { ContentTypeLink } from 'utility/utility'
import { SocialMediaChannelLinkFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'

import styles from './SocialMediaChannelLink.module.scss'

const socialMediaChannelUrl = (service: string, identifier: string): string => {
    switch (service) {
        case 'Twitter':
            return `https://twitter.com/${identifier}`
        case 'Facebook':
            return `https://www.facebook.com/${identifier}/`
        case 'LinkedIn':
            return identifier.includes('/') ? `https://www.linkedin.com/${identifier}/`
                : `https://www.linkedin.com/company/${identifier}/`
        case 'Instagram':
            return `https://www.instagram.com/${identifier}/`
        default:
            return ''
    }
}

export const SocialMediaChannelLink: ContentTypeLink<SocialMediaChannelLinkFragment> =
    ({
        name,
        service,
        identifier,
        icon,
        children,
        className
    }) =>
        <a
            href={socialMediaChannelUrl(service,identifier)}
            title={name}
            target='_blank'
            className={`${styles.Link} ${className}`}
        >
            {
                children ??
                    <ComponentImagePicture
                        {...icon}
                        className={styles.Image}
            />
            }
        </a>
