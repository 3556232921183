
import { ContentTypeLink } from 'utility/utility'
import { EventSeriesPageIdLinkFragment } from 'generated/graphql'
import { type SiteContext } from 'context/SiteContext'
import { type PageContext } from 'context/PageContext'
import { EventSeriesPageLink } from 'content-types/EventSeriesPage/EventSeriesPage.Link/EventSeriesPage.Link'

export const EventSeriesPageIdLink: ContentTypeLink<EventSeriesPageIdLinkFragment & {
    path: string
    override?: string
    siteContext: SiteContext
    pageContext: PageContext
}> =
    ({
        path,
        siteContext,
        pageContext,
        override,
        ...page
    }) => {

        const { allEventSeriesPages } = pageContext

        const target = allEventSeriesPages?.data.find( p => p.attributes?.eventSeriesPageId === path )

        return (
            target?.attributes?.slug === undefined ? <span>{page.children}</span> :
                <EventSeriesPageLink
                    override={override}
                    siteContext={siteContext}
                    {...{...page,...target.attributes}}
                />
        )

    }
